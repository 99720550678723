export function useUseHomePopup(preCb?: () => void) {
  const showCityPopup = useState('showCityPopup', () => false)
  const showDatePopup = useState('showDatePopup', () => false)

  const filterStore = useFilterConditionsStore()

  function handleHomeSearch() {
    if (!filterStore.filterConditions.selectedCity?.destinationId) {
      showCityPopup.value = true
      return
    }
    if (!filterStore.filterConditions.date) {
      showDatePopup.value = true
      return
    }
    preCb?.()
    navigateToList()
  }

  return {
    showCityPopup,
    showDatePopup,
    handleHomeSearch,
  }
}
